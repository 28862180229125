import {
    Family, FamilyFieldPaths,
    FamilyUpdateDtoInterface, PotentialDuplicateActionConstants
} from '@/families/models/family';
import { BaseStatuses } from '@/constants/status-constants';
import {
    FamilyEntry,
    ChildEntry,
    PotentialDuplicateActionOption, FieldOverwrite
} from '@/families/services/potential-duplicate-service';
import {
    ChildCreateDtoInterface,
    ChildFieldPaths,
    ChildPostDTO,
    ChildUpdateDtoInterface
} from '@/families/models/child';
import cloneDeep from 'lodash/cloneDeep';
import { ChangeStatus } from '@/families/change-status';
import { StatusChangeInterface } from '@/families/models/status';

/**
 * Filter duplicates, removing linked families and rejected ones, and ensure currentFamily only appears once and at the top
 * @param currentFamily
 * @param duplicates
 */
export function filterDuplicates(currentFamily: Family, duplicates: Array<Family>): Array<Family> {
    const excludedFamilyIds = currentFamily.linked_families?.length
        ? new Set(currentFamily.linked_families.map(linkedFamily => linkedFamily.family.id))
        : new Set();

    // Filter duplicates, removing linked families and rejected ones, and ensure currentFamily only appears once
    const filteredDuplicates = duplicates.filter(duplicate =>
        duplicate.id !== currentFamily.id &&
        !excludedFamilyIds.has(duplicate.id) &&
        duplicate.status?.id !== BaseStatuses.REJECTED
    );

    const currentCenterId = currentFamily.center?.id || null;

    // Separate duplicates by same center and other centers, then sort each group by id
    if (currentCenterId) {
        const sameCenterDuplicates = filteredDuplicates
            .filter(duplicate => duplicate.center && duplicate.center.id === currentCenterId)
            .sort((a, b) => a.id - b.id);

        const otherCenterDuplicates = filteredDuplicates
            .filter(duplicate => duplicate.center && duplicate.center.id !== currentCenterId)
            .sort((a, b) => a.id - b.id);
        return [currentFamily, ...sameCenterDuplicates, ...otherCenterDuplicates];
    }

    // Return currentFamily at the start, followed by the filtered duplicates
    return [currentFamily, ...filteredDuplicates];
}

/**
 * Get the dialog size based on the number of potential duplicates
 * @param numberOfFamilies
 */
export function getDialogSize(numberOfFamilies: number): string {
    switch (true) {
        case numberOfFamilies > 3:
            return 'potential-duplicate-dialog-x-large';
        case numberOfFamilies === 3:
            return 'potential-duplicate-dialog-large';
        default:
            return 'potential-duplicate-dialog-medium';
    }
}

/**
 * Groups children by full name across all families, ensuring each key in the map has an array
 * with entries for each family (placeholders added for families without that child).
 *
 * @param familiesDto - Array of FamilyUpdateDtoInterface
 * @param families - Array of Family
 * @returns Map where each key is a child's full name and the value is an array of MappedChild entries.
 */
export function groupChildrenByFullName(familiesDto: Array<FamilyUpdateDtoInterface>, families: Array<Family>): Map<string, Array<ChildEntry>> {
    const changeStatusUtil = new ChangeStatus();
    const childrenMap = new Map<string, Array<ChildEntry>>();

    const numberOfFamilies = familiesDto.length;

    // Step 1: Populate the map with child entries from each family
    familiesDto.forEach((familyDto, familyIndex) => {
        familyDto.children.forEach((childDto, childIndex) => {
            const fullName = `${childDto.first_name} ${childDto.last_name}`; // Create full name key

            if (!childrenMap.has(fullName)) {
                // Initialize with placeholders to ensure the array length matches the number of families
                childrenMap.set(fullName, Array(numberOfFamilies).fill(null));
            }

            // Place the actual child at the correct family index
            childrenMap.get(fullName)![familyIndex] = {
                childDto: childDto,
                child: families[familyIndex].children[childIndex],
                family: families[familyIndex],
                availableStatuses: [],
                statusUpdates: changeStatusUtil.setStatusChangeDetails(childDto.status || 1, families[familyIndex], families[familyIndex].children[childIndex])
            };
        });
    });

    // Step 2: Fill in missing children with placeholders
    childrenMap.forEach((childrenList) => {
        for (let i = 0; i < numberOfFamilies; i++) {
            if (!childrenList[i]) {
                // Create a placeholder child if the family does not have this child
                const childDto: ChildCreateDtoInterface | ChildUpdateDtoInterface = new ChildPostDTO();
                childDto.id = -1 * (families[i].id + families[i].children.length + i);
                childDto.status = null;

                // Add the placeholder to the list at the correct family index
                childrenList[i] = {
                    childDto: childDto,
                    child: null,
                    family: families[i],
                    availableStatuses: [], // Will calculate this later,
                    statusUpdates: null
                };
            }
        }

        // Step 3: Collect unique statuses across all families for the same child name
        const uniqueStatuses = new Set<number>();

        childrenList.forEach((entry) => {
            const status = entry.childDto.status;

            if (status === null || status === undefined) return; // Skip if status is null
            uniqueStatuses.add(status === 0 ? 1 : status); // Insert 1 if status is 0, otherwise insert status as-is
        });

        const statusesArray = Array.from(uniqueStatuses); // Convert Set to Array

        // Step 4: Assign availableStatuses to each child (including placeholders)
        childrenList.forEach((entry) => {
            entry.availableStatuses = statusesArray;
        });
    });

    return childrenMap;
}

export function getNestedProperty(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

/**
 * Utility function to check if there are differences for a specific field across families.
 * @param families - Array of FamilyUpdateDtoInterface
 * @param field - The field name to compare
 * @returns boolean - true if there are differences, false otherwise
 */
export function hasFamilyDifferences(families: Array<FamilyUpdateDtoInterface>, field: string): boolean {
    const uniqueValues = new Set(
        families.map(family => getNestedProperty(family, field))
    );
    // Remove empty or null or undefined values before checking uniqueness
    uniqueValues.delete('');
    uniqueValues.delete(null);
    uniqueValues.delete(undefined);
    return uniqueValues.size > 1;
}

/**
 * Utility function to generate a map of fields with their respective differences across families.
 * @param families - Array of FamilyUpdateDtoInterface
 * @returns object - An object with keys as field names and values as booleans indicating differences
 */
export function generateDifferencesFamilyMap(families: Array<FamilyUpdateDtoInterface>): Record<string, boolean> {
    return {
        name: hasFamilyDifferences(families, FamilyFieldPaths.FIRST_NAME) || hasFamilyDifferences(families, FamilyFieldPaths.LAST_NAME),
        address1: hasFamilyDifferences(families, FamilyFieldPaths.ADDRESS1),
        address2: hasFamilyDifferences(families, FamilyFieldPaths.LOCALITY) ||
                 hasFamilyDifferences(families, FamilyFieldPaths.REGION) ||
                 hasFamilyDifferences(families, FamilyFieldPaths.POSTAL_CODE),
        phone: hasFamilyDifferences(families, FamilyFieldPaths.PHONE_NUMBER),
        email: hasFamilyDifferences(families, FamilyFieldPaths.EMAIL),
        inquiry_type: hasFamilyDifferences(families, FamilyFieldPaths.INQUIRY_TYPE),
        source_type: hasFamilyDifferences(families, FamilyFieldPaths.SOURCE_TYPE),
        status: hasFamilyDifferences(families, FamilyFieldPaths.STATUS)
    };
}

/**
 * Utility function to check if there are differences for a specific field across grouped children.
 * @param children
 * @param field - The field name to compare
 * @returns boolean - true if there are differences, false otherwise
 */
export function hasChildDifferences(children: Array<ChildUpdateDtoInterface | ChildCreateDtoInterface>, field: string): boolean {
    const uniqueValues = new Set(children.map(child => getNestedProperty(child, field)));
    // Remove empty or null or undefined values before checking uniqueness
    uniqueValues.delete('');
    uniqueValues.delete(null);
    uniqueValues.delete(undefined);
    return uniqueValues.size > 1;
}

/**
 * Checks if there are differences in start dates across status updates.
 * @param statusUpdates - Array of StatusChangeInterface or null values
 * @returns boolean - True if there are multiple unique start dates, false otherwise
 */
export function hasChildStartDatesDifferences(statusUpdates: Array<StatusChangeInterface | null>): boolean {
    const changeStatusUtil = new ChangeStatus();
    const uniqueStartDates = new Set<string>();

    for (const statusUpdate of statusUpdates) {
        if (!statusUpdate) continue; // Skip null status updates

        if (changeStatusUtil.hasActualStartDate(statusUpdate.status)) {
            if (statusUpdate.actual_start_date) {
                uniqueStartDates.add(statusUpdate.actual_start_date);
            }
        } else {
            if (statusUpdate.expected_start_date) {
                uniqueStartDates.add(statusUpdate.expected_start_date);
            }
        }
    }

    return uniqueStartDates.size > 1;
}

/**
 * Utility function to generate a map of fields with their respective differences across grouped children.
 * @param children - Array of GroupedChildEntry objects grouped by child name
 * @param statusUpdates
 * @returns object - An object with keys as field names and values as booleans indicating differences
 */
export function generateDifferencesChildrenMap(children: Array<ChildUpdateDtoInterface | ChildCreateDtoInterface>, statusUpdates: Array<StatusChangeInterface | null>): Record<string, boolean> {
    return {
        name: hasChildDifferences(children, ChildFieldPaths.FIRST_NAME) || hasChildDifferences(children, ChildFieldPaths.LAST_NAME),
        date_of_birth: hasChildDifferences(children, ChildFieldPaths.DATE_OF_BIRTH),
        status: hasChildDifferences(children, ChildFieldPaths.STATUS),
        start_date: hasChildStartDatesDifferences(statusUpdates)
    };
}

/**
 * Utility function to generate action options for a specific family.
 * The options are derived from the provided families list, family map, and selected actions.
 *
 * @param familyId - The ID of the family to get action options for.
 * @param familyEntries
 * @param selectedActionOptions - A record of selected actions for each family.
 * @returns An array of PotentialDuplicateActionOption objects.
 */
export function getActionOptionsForFamily(
    familyId: number,
    familyEntries: Array<FamilyEntry>,
    selectedActionOptions: Record<number, PotentialDuplicateActionOption | null>
): Array<PotentialDuplicateActionOption> {
    const options: PotentialDuplicateActionOption[] = [];

    // Always add "Save This Record"
    options.push({
        text: 'Save This Record',
        value: `${PotentialDuplicateActionConstants.SAVE}-${familyId}`
    });

    // Add "Merge to ID ___" for records at the same location marked as "Save This Record"
    familyEntries.forEach((familyEntry) => {
        if (
            familyEntry.familyDto.id !== familyId &&
            familyEntry.familyDto.primary_guardian.center_id === familyEntries.find(f => f.familyDto.id === familyId)?.familyDto.primary_guardian.center_id && // Same location
            selectedActionOptions[familyEntry.familyDto.id]?.value.split('-')[0] === PotentialDuplicateActionConstants.SAVE// Marked as "Save This Record"
        ) {
            options.push({
                text: `Merge to ID ${familyEntry.familyDto.id}`,
                value: `${PotentialDuplicateActionConstants.MERGE}-${familyEntry.familyDto.id}`
            });
        }
    });

    // Add "Link to ID ___" for records at different locations marked as "Save This Record"
    familyEntries.forEach((familyEntry) => {
        if (
            familyEntry.familyDto.id !== familyId &&
            familyEntry.familyDto.primary_guardian.center_id !== familyEntries.find(f => f.familyDto.id === familyId)?.familyDto.primary_guardian.center_id && // Different location
            selectedActionOptions[familyEntry.familyDto.id]?.value.split('-')[0] === PotentialDuplicateActionConstants.SAVE // Marked as "Save This Record"
        ) {
            options.push({
                text: `Link to ID ${familyEntry.familyDto.id}`,
                value: `${PotentialDuplicateActionConstants.LINK}-${familyEntry.familyDto.id}`
            });
        }
    });

    // Add "Skip/Hide This Record" (not for the Family Being Viewed)
    if (familyId !== familyEntries[0].familyDto.id) {
        options.push({
            text: 'Skip/Hide This Record',
            value: `${PotentialDuplicateActionConstants.HIDE}-${familyId}`
        });
    }

    // Add "Reject This Record"
    options.push({
        text: 'Reject This Record',
        value: `${PotentialDuplicateActionConstants.REJECT}-${familyId}`
    });

    return options;
}

/**
 * Merges the source family into the target family.
 * This function modifies the target family by combining its data with the source family.
 * The source family data remains unchanged.
 *
 * @returns The updated target family.
 * @param targetFamilyEntry
 * @param sourceFamilyEntry
 * @param familyEntries
 */
export function mergeGuardianInfo(targetFamilyEntry: FamilyEntry, sourceFamilyEntry: FamilyEntry, familyEntries: Array<FamilyEntry>): void {
    const targetFamilyDto = targetFamilyEntry.familyDto;
    const sourceFamilyDto = sourceFamilyEntry.familyDto;
    targetFamilyDto.primary_guardian.first_name = targetFamilyDto.primary_guardian.first_name || sourceFamilyDto.primary_guardian.first_name;
    targetFamilyDto.primary_guardian.last_name = targetFamilyDto.primary_guardian.last_name || sourceFamilyDto.primary_guardian.last_name;
    if (targetFamilyDto.primary_guardian.address && sourceFamilyDto.primary_guardian.address) {
        targetFamilyDto.primary_guardian.address.address1 = targetFamilyDto.primary_guardian.address.address1 || sourceFamilyDto.primary_guardian.address.address1;
        targetFamilyDto.primary_guardian.address.locality = targetFamilyDto.primary_guardian.address.locality || sourceFamilyDto.primary_guardian.address.locality;

        targetFamilyDto.primary_guardian.address.region = targetFamilyDto.primary_guardian.address.region || sourceFamilyDto.primary_guardian.address.region;

        targetFamilyDto.primary_guardian.address.postcode = targetFamilyDto.primary_guardian.address.postcode || sourceFamilyDto.primary_guardian.address.postcode;
    }

    if (targetFamilyDto.primary_guardian.primary_phone && sourceFamilyDto.primary_guardian.primary_phone) {
        targetFamilyDto.primary_guardian.primary_phone.number_e164 = targetFamilyDto.primary_guardian.primary_phone.number_e164 || sourceFamilyDto.primary_guardian.primary_phone.number_e164;
    }

    targetFamilyDto.primary_guardian.email = targetFamilyDto.primary_guardian.email || sourceFamilyDto.primary_guardian.email;
    targetFamilyDto.inquiry_type = targetFamilyDto.inquiry_type || sourceFamilyDto.inquiry_type;
    targetFamilyDto.source_type = targetFamilyDto.source_type || sourceFamilyDto.source_type;

    const targetFamily = targetFamilyEntry.family;
    const sourceFamily = sourceFamilyEntry.family;
    const targetStatusUpdates = targetFamilyEntry.statusUpdates;
    const sourceStatusUpdates = sourceFamilyEntry.statusUpdates;

    if (targetFamily.children.length === 0 && sourceFamily.children.length === 0) {
        if (targetStatusUpdates && sourceStatusUpdates && targetStatusUpdates.status === sourceStatusUpdates.status) {
            targetStatusUpdates.reason = targetStatusUpdates.reason || sourceStatusUpdates.reason;
            targetStatusUpdates.comments = targetStatusUpdates.comments || sourceStatusUpdates.comments;
        } else if (!targetStatusUpdates && sourceStatusUpdates) {
            targetFamilyEntry.statusUpdates = cloneDeep(sourceStatusUpdates);
            targetFamilyEntry.statusUpdates.family_id = targetFamily.id;
        }
    }
    const updatedFamilyEntry = { familyDto: targetFamilyDto, family: targetFamily, statusUpdates: targetStatusUpdates };
    const updatedFamilyIndex = familyEntries.findIndex(entry => entry.familyDto.id === targetFamilyDto.id);
    familyEntries[updatedFamilyIndex] = updatedFamilyEntry;
}

/**
 * Merges the source family into the target family.
 * This function modifies the target family by combining its data with the source family.
 * The source family data remains unchanged.
 *
 * @returns The updated target family.
 * @param targetFamilyIndex
 * @param sourceFamilyIndex
 * @param childrenGroupedByName
 */
export function mergeChildInfo(
    targetFamilyIndex: number,
    sourceFamilyIndex: number,
    childrenGroupedByName: Record<string, Array<ChildEntry>>
): void {
    for (const [fullName, childrenList] of Object.entries(childrenGroupedByName)) {
        const targetChildEntry = childrenList[targetFamilyIndex];
        const sourceChildEntry = childrenList[sourceFamilyIndex];

        if (!targetChildEntry || !sourceChildEntry) continue;

        const targetChildDto = targetChildEntry.childDto;
        const sourceChildDto = sourceChildEntry.childDto;

        // Merge basic child information
        targetChildDto.first_name = targetChildDto.first_name || sourceChildDto.first_name;
        targetChildDto.last_name = targetChildDto.last_name || sourceChildDto.last_name;
        targetChildDto.date_of_birth = targetChildDto.date_of_birth || sourceChildDto.date_of_birth;

        // Merge status updates
        const targetStatusUpdates = targetChildEntry.statusUpdates;
        const sourceStatusUpdates = sourceChildEntry.statusUpdates;

        if (targetStatusUpdates && sourceStatusUpdates && sourceStatusUpdates.status === targetStatusUpdates.status) {
            targetStatusUpdates.actual_start_date = targetStatusUpdates.actual_start_date || sourceStatusUpdates.actual_start_date;
            targetStatusUpdates.date = targetStatusUpdates.date || sourceStatusUpdates.date;
            targetStatusUpdates.expected_start_date = targetStatusUpdates.expected_start_date || sourceStatusUpdates.expected_start_date;
            targetStatusUpdates.reason = targetStatusUpdates.reason || sourceStatusUpdates.reason;
            targetStatusUpdates.comments = targetStatusUpdates.comments || sourceStatusUpdates.comments;

            // Merge wait list details
            if (targetStatusUpdates.wait_list_details && sourceStatusUpdates.wait_list_details) {
                targetStatusUpdates.wait_list_details.is_child_of_staff =
                        targetStatusUpdates.wait_list_details.is_child_of_staff || sourceStatusUpdates.wait_list_details.is_child_of_staff;
                targetStatusUpdates.wait_list_details.is_sibling_in_care =
                        targetStatusUpdates.wait_list_details.is_sibling_in_care || sourceStatusUpdates.wait_list_details.is_sibling_in_care;
                targetStatusUpdates.wait_list_details.fee = targetStatusUpdates.wait_list_details.fee || sourceStatusUpdates.wait_list_details.fee;
                targetStatusUpdates.wait_list_details.fee_paid_date =
                        targetStatusUpdates.wait_list_details.fee_paid_date || sourceStatusUpdates.wait_list_details.fee_paid_date;
                targetStatusUpdates.wait_list_details.is_fee_paid =
                        targetStatusUpdates.wait_list_details.is_fee_paid || sourceStatusUpdates.wait_list_details.is_fee_paid;
                targetStatusUpdates.wait_list_details.priority =
                        targetStatusUpdates.wait_list_details.priority || sourceStatusUpdates.wait_list_details.priority;
                targetStatusUpdates.wait_list_details.type =
                        targetStatusUpdates.wait_list_details.type || sourceStatusUpdates.wait_list_details.type;
            }

            // Merge withdrawn details
            if (targetStatusUpdates.withdrawn_details && sourceStatusUpdates.withdrawn_details) {
                targetStatusUpdates.withdrawn_details.good_standing =
                        targetStatusUpdates.withdrawn_details.good_standing || sourceStatusUpdates.withdrawn_details.good_standing;
                targetStatusUpdates.withdrawn_details.is_eligible_for_reenrollment =
                        targetStatusUpdates.withdrawn_details.is_eligible_for_reenrollment || sourceStatusUpdates.withdrawn_details.is_eligible_for_reenrollment;
            }

            targetChildEntry.statusUpdates = targetStatusUpdates;
        } else if (!targetChildEntry.statusUpdates && sourceChildEntry.statusUpdates) {
            targetChildEntry.statusUpdates = cloneDeep(sourceChildEntry.statusUpdates);
            targetChildEntry.statusUpdates.family_id = targetChildEntry.family.id;
        }

        // Update the target entry in childrenGroupedByName
        childrenGroupedByName[fullName][targetFamilyIndex] = targetChildEntry;
    }
}

/**
* Filters out records with hide actions from the provided options.
*
* @param actionOptions - Record of action options keyed by ID
* @returns New record containing only non-hide actions
*/
export function filterNonHideActions(
    actionOptions: Record<number, PotentialDuplicateActionOption | null>
): Record<number, PotentialDuplicateActionOption | null> {
    const filteredActions: Record<number, PotentialDuplicateActionOption | null> = {};

    for (const [id, option] of Object.entries(actionOptions)) {
        if (!option) continue;

        const [actionType] = option.value.split('-');
        if (actionType !== PotentialDuplicateActionConstants.HIDE) {
            filteredActions[Number(id)] = option;
        }
    }

    return filteredActions;
}

/**
 *
 * @param beforeFamilyEntry
 * @param afterFamilyEntry
 * Compares two FamilyEntry objects (before/after a merge) and
 * returns a list of FieldOverwrite objects for each field
 * that changed from "null" (or empty) to a new non-null value.
 */
export function buildFamilyOverwritesFromDiffs(
    beforeFamilyEntry: FamilyEntry,
    afterFamilyEntry: FamilyEntry
): Array<FieldOverwrite> {
    const overwrites: Array<FieldOverwrite> = [];
    const beforeDto = beforeFamilyEntry.familyDto;
    const afterDto = afterFamilyEntry.familyDto;
    const beforeGuardian = beforeDto.primary_guardian;
    const afterGuardian = afterDto.primary_guardian;

    const fieldChecks: Array<{ path: string; before: any; after: any }> = [
        { path: 'familyDto.primary_guardian.first_name', before: beforeGuardian.first_name, after: afterGuardian.first_name },
        { path: 'familyDto.primary_guardian.last_name', before: beforeGuardian.last_name, after: afterGuardian.last_name },
        { path: 'familyDto.primary_guardian.address.address1', before: beforeGuardian.address?.address1, after: afterGuardian.address?.address1 },
        { path: 'familyDto.primary_guardian.address.locality', before: beforeGuardian.address?.locality, after: afterGuardian.address?.locality },
        { path: 'familyDto.primary_guardian.address.region', before: beforeGuardian.address?.region, after: afterGuardian.address?.region },
        { path: 'familyDto.primary_guardian.address.postcode', before: beforeGuardian.address?.postcode, after: afterGuardian.address?.postcode },
        { path: 'familyDto.primary_guardian.primary_phone.number_e164', before: beforeGuardian.primary_phone?.number_e164, after: afterGuardian.primary_phone?.number_e164 },
        { path: 'familyDto.primary_guardian.email', before: beforeGuardian.email, after: afterGuardian.email },
        { path: 'familyDto.inquiry_type', before: beforeDto.inquiry_type, after: afterDto.inquiry_type },
        { path: 'familyDto.source_type', before: beforeDto.source_type, after: afterDto.source_type }
    ];

    // Add overwrites if the field changed from null/empty to a new non-null value
    for (const { path, before, after } of fieldChecks) {
        if (!before && after) {
            overwrites.push({ fieldPath: path, oldValue: before, mergedValue: after });
        }
    }

    const beforeStatus = beforeFamilyEntry.statusUpdates;
    const afterStatus = afterFamilyEntry.statusUpdates;

    if (beforeStatus && afterStatus && beforeStatus.status === afterStatus.status) {
        const statusFields: Array<{ path: string; before: any; after: any }> = [
            { path: 'statusUpdates.reason', before: beforeStatus.reason, after: afterStatus.reason },
            { path: 'statusUpdates.comments', before: beforeStatus.comments, after: afterStatus.comments }
        ];

        for (const { path, before, after } of statusFields) {
            if (!before && after) {
                overwrites.push({ fieldPath: path, oldValue: before, mergedValue: after });
            }
        }
    } else if (!beforeStatus && afterStatus) {
        const newStatusFields: Array<{ path: string; value: any }> = [
            { path: 'statusUpdates.status', value: afterStatus.status },
            { path: 'statusUpdates.reason', value: afterStatus.reason },
            { path: 'statusUpdates.comments', value: afterStatus.comments }
        ];

        for (const { path, value } of newStatusFields) {
            if (value !== null && value !== undefined) {
                overwrites.push({ fieldPath: path, oldValue: null, mergedValue: value });
            }
        }
    }

    return overwrites;
}

/**
 *
 * @param familyIndex
 * @param beforeChildrenGroupedByName
 * @param afterChildrenGroupedByName
 * Compares two versions of children grouped by name (before/after a merge) and
 * returns a record where the key is the child's name, and the value is an array
 * of FieldOverwrite objects indicating which fields changed from null/empty to a non-null value.
 */
export function buildChildrenOverwritesFromDiffs(
    familyIndex: number,
    beforeChildrenGroupedByName: Record<string, Array<ChildEntry>>,
    afterChildrenGroupedByName: Record<string, Array<ChildEntry>>
): Record<string, Array<FieldOverwrite>> {
    const overwritesByChildName: Record<string, Array<FieldOverwrite>> = {};

    for (const [childName, afterEntries] of Object.entries(afterChildrenGroupedByName)) {
        const beforeEntries = beforeChildrenGroupedByName[childName];
        overwritesByChildName[childName] = [];

        // Ensure the child exists at the given family index in both before and after states
        const beforeEntry = beforeEntries[familyIndex];
        const afterEntry = afterEntries[familyIndex];

        if (!beforeEntry || !afterEntry) continue; // Skip if missing

        const overwrites: Array<FieldOverwrite> = [];

        const beforeDto = beforeEntry.childDto;
        const afterDto = afterEntry.childDto;

        // Check basic child fields
        const fieldChecks: Array<{ path: string; before: any; after: any }> = [
            { path: 'childDto.first_name', before: beforeDto.first_name, after: afterDto.first_name },
            { path: 'childDto.last_name', before: beforeDto.last_name, after: afterDto.last_name },
            { path: 'childDto.date_of_birth', before: beforeDto.date_of_birth, after: afterDto.date_of_birth }
        ];

        for (const { path, before, after } of fieldChecks) {
            if (!before && after) {
                overwrites.push({ fieldPath: path, oldValue: before, mergedValue: after });
            }
        }

        // Handle status updates
        const beforeStatus = beforeEntry.statusUpdates;
        const afterStatus = afterEntry.statusUpdates;

        if (beforeStatus && afterStatus && beforeStatus.status === afterStatus.status) {
            const statusFields = [
                { path: 'statusUpdates.actual_start_date', before: beforeStatus.actual_start_date, after: afterStatus.actual_start_date },
                { path: 'statusUpdates.date', before: beforeStatus.date, after: afterStatus.date },
                { path: 'statusUpdates.expected_start_date', before: beforeStatus.expected_start_date, after: afterStatus.expected_start_date },
                { path: 'statusUpdates.reason', before: beforeStatus.reason, after: afterStatus.reason },
                { path: 'statusUpdates.comments', before: beforeStatus.comments, after: afterStatus.comments }
            ];

            for (const { path, before, after } of statusFields) {
                if (!before && after) {
                    overwrites.push({ fieldPath: path, oldValue: before, mergedValue: after });
                }
            }

            // Handle waitlist details
            if (beforeStatus.wait_list_details && afterStatus.wait_list_details) {
                const waitlistFields = [
                    { path: 'statusUpdates.wait_list_details.is_child_of_staff', before: beforeStatus.wait_list_details.is_child_of_staff, after: afterStatus.wait_list_details.is_child_of_staff },
                    { path: 'statusUpdates.wait_list_details.is_sibling_in_care', before: beforeStatus.wait_list_details.is_sibling_in_care, after: afterStatus.wait_list_details.is_sibling_in_care },
                    { path: 'statusUpdates.wait_list_details.fee', before: beforeStatus.wait_list_details.fee, after: afterStatus.wait_list_details.fee },
                    { path: 'statusUpdates.wait_list_details.fee_paid_date', before: beforeStatus.wait_list_details.fee_paid_date, after: afterStatus.wait_list_details.fee_paid_date },
                    { path: 'statusUpdates.wait_list_details.is_fee_paid', before: beforeStatus.wait_list_details.is_fee_paid, after: afterStatus.wait_list_details.is_fee_paid },
                    { path: 'statusUpdates.wait_list_details.priority', before: beforeStatus.wait_list_details.priority, after: afterStatus.wait_list_details.priority },
                    { path: 'statusUpdates.wait_list_details.type', before: beforeStatus.wait_list_details.type, after: afterStatus.wait_list_details.type }
                ];

                for (const { path, before, after } of waitlistFields) {
                    if (!before && after) {
                        overwrites.push({ fieldPath: path, oldValue: before, mergedValue: after });
                    }
                }
            }

            // Handle withdrawn details
            if (beforeStatus.withdrawn_details && afterStatus.withdrawn_details) {
                const withdrawnFields = [
                    { path: 'statusUpdates.withdrawn_details.good_standing', before: beforeStatus.withdrawn_details.good_standing, after: afterStatus.withdrawn_details.good_standing },
                    { path: 'statusUpdates.withdrawn_details.is_eligible_for_reenrollment', before: beforeStatus.withdrawn_details.is_eligible_for_reenrollment, after: afterStatus.withdrawn_details.is_eligible_for_reenrollment }
                ];

                for (const { path, before, after } of withdrawnFields) {
                    if (!before && after) {
                        overwrites.push({ fieldPath: path, oldValue: before, mergedValue: after });
                    }
                }
            }
        } else if (!beforeStatus && afterStatus) {
            const newStatusFields: Array<{ path: string; value: any }> = [
                { path: 'statusUpdates.status', value: afterStatus.status },
                { path: 'statusUpdates.reason', value: afterStatus.reason },
                { path: 'statusUpdates.comments', value: afterStatus.comments },
                { path: 'statusUpdates.actual_start_date', value: afterStatus.actual_start_date },
                { path: 'statusUpdates.date', value: afterStatus.date },
                { path: 'statusUpdates.expected_start_date', value: afterStatus.expected_start_date }
            ];

            for (const { path, value } of newStatusFields) {
                if (value !== null && value !== undefined) {
                    overwrites.push({ fieldPath: path, oldValue: null, mergedValue: value });
                }
            }

            // Handle wait list details
            if (afterStatus.wait_list_details) {
                const waitlistFields = [
                    { path: 'statusUpdates.wait_list_details.is_child_of_staff', value: afterStatus.wait_list_details.is_child_of_staff },
                    { path: 'statusUpdates.wait_list_details.is_sibling_in_care', value: afterStatus.wait_list_details.is_sibling_in_care },
                    { path: 'statusUpdates.wait_list_details.fee', value: afterStatus.wait_list_details.fee },
                    { path: 'statusUpdates.wait_list_details.fee_paid_date', value: afterStatus.wait_list_details.fee_paid_date },
                    { path: 'statusUpdates.wait_list_details.is_fee_paid', value: afterStatus.wait_list_details.is_fee_paid },
                    { path: 'statusUpdates.wait_list_details.priority', value: afterStatus.wait_list_details.priority },
                    { path: 'statusUpdates.wait_list_details.type', value: afterStatus.wait_list_details.type }
                ];

                for (const { path, value } of waitlistFields) {
                    if (value !== null && value !== undefined) {
                        overwrites.push({ fieldPath: path, oldValue: null, mergedValue: value });
                    }
                }
            }

            // Handle withdrawn details
            if (afterStatus.withdrawn_details) {
                const withdrawnFields = [
                    { path: 'statusUpdates.withdrawn_details.good_standing', value: afterStatus.withdrawn_details.good_standing },
                    { path: 'statusUpdates.withdrawn_details.is_eligible_for_reenrollment', value: afterStatus.withdrawn_details.is_eligible_for_reenrollment }
                ];

                for (const { path, value } of withdrawnFields) {
                    if (value !== null && value !== undefined) {
                        overwrites.push({ fieldPath: path, oldValue: null, mergedValue: value });
                    }
                }
            }
        }

        if (overwrites.length > 0) {
            overwritesByChildName[childName] = overwrites;
        }
    }
    return overwritesByChildName;
}
