import {
    Family,
    FamilyUpdateDtoInterface, PendingFamily, PotentialDuplicateActionConstants
} from '@/families/models/family';
import { getModule } from 'vuex-module-decorators';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { FamilyMapper } from '@/families/mappers/family-mapper';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { Child, ChildCreateDtoInterface, ChildUpdateDtoInterface } from '@/families/models/child';
import {
    generateDifferencesChildrenMap,
    generateDifferencesFamilyMap,
    groupChildrenByFullName
} from '@/families/potential-duplicate-utils';
import { StatusChangeInterface } from '@/families/models/status';
import { ChangeStatus } from '@/families/change-status';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { ChildrenRepository } from '@/families/repositories/children-repository';
import { StatusChangeRepository } from '@/families/repositories/status-change-repository';
import { StatusChangesStore } from '@/families/store/status-changes-store';
import { PendingFamilyService } from '@/families/pending-family-service';

export interface ChildEntry {
    childDto: ChildUpdateDtoInterface | ChildCreateDtoInterface;
    child: Child | null;
    family: Family | PendingFamily;
    availableStatuses: Array<number>;
    statusUpdates: StatusChangeInterface | null;
}

export interface FamilyEntry {
    familyDto: FamilyUpdateDtoInterface;
    family: Family | PendingFamily;
    statusUpdates: StatusChangeInterface | null;
}

export interface PotentialDuplicateActionOption {
    text: string;
    value: string;
}

export interface PotentialDuplicateActionsSet {
    save: Array<PotentialDuplicateActionOption>;
    merge: Array<PotentialDuplicateActionOption>;
    link: Array<PotentialDuplicateActionOption>;
    hide: Array<PotentialDuplicateActionOption>;
    reject: Array<PotentialDuplicateActionOption>;
}

export interface FieldOverwrite {
    fieldPath: string;
    oldValue: any;
    mergedValue: any;
}

export interface MergeLayer {
    sourceId: number;
    familyOverwrites: Array<FieldOverwrite>;
    childrenOverwrites: Record<string, Array<FieldOverwrite>>;
}

export default class PotentialDuplicateService {
    private centerStore = getModule(CentersStore);
    private crmTypesStore = getModule(CrmTypesStore);
    private centerNames: Array<string> = [];
    private currentFamilyEntries: Array<FamilyEntry> = [];
    private familyMapper = new FamilyMapper();
    private inquiryTypes: Array<CrmTypeOption> = [];
    private sourceTypes: Array<CrmTypeOption> = [];
    private currentChildrenGroupedByName: Record<string, Array<ChildEntry>> = {};
    private familyDifferencesRecord: Record<string, boolean> = {};
    private childrenDifferencesArray: Array<{ name: string; differences: Record<string, boolean> }> = [];
    private statusIdsForChildlessFamilies: Array<number> = [];
    private changeStatusUtil = new ChangeStatus();
    private familiesRepository = new FamiliesRepository();
    private childrenRepository = new ChildrenRepository();
    private statusChangeRepository = new StatusChangeRepository();
    private statusChangesStore = getModule(StatusChangesStore);
    private pendingFamilyService = new PendingFamilyService();

    get inquiryTypesOptions() {
        return this.inquiryTypes;
    }

    get sourceTypesOptions() {
        return this.sourceTypes;
    }

    get statusesForChildlessFamilies() {
        return this.statusIdsForChildlessFamilies;
    }

    get centerRows() {
        return this.centerNames;
    }

    get familyEntries() {
        return this.currentFamilyEntries;
    }

    get childrenGrouped() {
        return this.currentChildrenGroupedByName;
    }

    get familyDifferences() {
        return this.familyDifferencesRecord;
    }

    get childrenDifferences() {
        return this.childrenDifferencesArray;
    }

    public updateFamilyEntries(value: Array<FamilyEntry>) {
        this.currentFamilyEntries = value;
    }

    public updateChildrenGroupedByName(value: Record<string, Array<ChildEntry>>) {
        this.currentChildrenGroupedByName = value;
    }

    async init(families: Array<Family | PendingFamily>) {
        await this.centerStore.initAccessibleCenters();
        this.currentFamilyEntries = [];
        this.centerNames = [];
        this.currentChildrenGroupedByName = {};
        this.familyDifferencesRecord = {};
        this.childrenDifferencesArray = [];

        for (const family of families) {
            const familyDto = this.familyMapper.toUpdateDto(family);
            this.currentFamilyEntries.push(
                {
                    familyDto,
                    family,
                    statusUpdates: this.changeStatusUtil.setStatusChangeDetails(familyDto.status || 1, family, null)
                }
            );
            if (familyDto.primary_guardian.center_id) {
                const center = this.centerStore.storedAccessibleCenters.find(center => center.id === familyDto.primary_guardian.center_id);
                if (center) {
                    this.centerNames.push(center.name);
                }
            }
        }

        const currentFamiliesDto = this.currentFamilyEntries.map(familyEntry => familyEntry.familyDto);
        this.currentChildrenGroupedByName = Object.fromEntries(groupChildrenByFullName(currentFamiliesDto, families).entries());
        this.childrenDifferencesArray = Object.keys(this.currentChildrenGroupedByName).map(name => ({
            name,
            differences: generateDifferencesChildrenMap(
                this.currentChildrenGroupedByName[name].map(childEntry => childEntry.childDto),
                this.currentChildrenGroupedByName[name].map(childEntry => childEntry.statusUpdates)
            )
        }));
        this.familyDifferencesRecord = generateDifferencesFamilyMap(currentFamiliesDto);
    }

    async setupSelectListOptions(familyEntries: Array<FamilyEntry>) {
        const familyInquiryPromise = this.crmTypesStore.initList(CrmTypeList.FAMILY_INQUIRY);
        const familySourcePromise = this.crmTypesStore.initList(CrmTypeList.FAMILY_SOURCE);
        await Promise.all([familyInquiryPromise, familySourcePromise]);

        this.inquiryTypes = [];
        this.sourceTypes = [];
        this.statusIdsForChildlessFamilies = [];

        const inquiryTypesSet: Set <number> = new Set();
        const sourceTypesSet: Set <number> = new Set();
        const statusIdsForChildlessFamiliesSet: Set<number> = new Set();

        for (const familyEntry of familyEntries) {
            inquiryTypesSet.add(familyEntry.familyDto.inquiry_type);
            sourceTypesSet.add(familyEntry.familyDto.source_type);
            if (familyEntry.familyDto.children.length === 0) {
                const currentStatus = familyEntry.familyDto.status || 1; // Default to "1" status if status is pending 0 or null
                statusIdsForChildlessFamiliesSet.add(currentStatus);
            }
        }
        this.inquiryTypes = this.crmTypesStore.listOptions(CrmTypeList.FAMILY_INQUIRY).filter(option => inquiryTypesSet.has(option.id));
        this.sourceTypes = this.crmTypesStore.listOptions(CrmTypeList.FAMILY_SOURCE).filter(option => sourceTypesSet.has(option.id));
        this.statusIdsForChildlessFamilies = Array.from(statusIdsForChildlessFamiliesSet);
    }

    async save(selectedActions: Record<string, PotentialDuplicateActionOption | null>, isPending = false) {

        for (const [i, familyEntry] of this.currentFamilyEntries.entries()) {
            const currentFamilyId = familyEntry.family.id;

            // Update family data

            const selectedAction = selectedActions[currentFamilyId];
            if (selectedAction) {
                await this.familiesRepository.update(familyEntry.familyDto);

                const [actionType, targetFamilyString] = selectedAction.value.split('-');

                // Anything other than reject should be accepted
                if (isPending && i === 0 && (actionType === PotentialDuplicateActionConstants.LINK || actionType === PotentialDuplicateActionConstants.SAVE)) {
                    await this.pendingFamilyService.acceptFamily(currentFamilyId);
                }

                // Update family status
                if (familyEntry.statusUpdates) {
                    await this.statusChangeRepository.changeStatus(familyEntry.statusUpdates, true);
                    this.statusChangesStore.clear(currentFamilyId);
                }

                // Process children grouped by name
                for (const [, childEntries] of Object.entries(this.currentChildrenGroupedByName)) {
                    const currentChildEntry = childEntries[i];
                    const currentChildId = currentChildEntry.childDto.id;

                    if (currentChildId) {
                        // Existing child
                        if (currentChildId > 0) {
                            await this.childrenRepository.update(currentFamilyId, currentChildEntry.childDto as ChildUpdateDtoInterface);
                            if (currentChildEntry.statusUpdates) {
                                await this.statusChangeRepository.changeStatus(currentChildEntry.statusUpdates, true);
                                this.statusChangesStore.clear(currentChildId);
                            }
                        } else {
                            // New child, only add new child if first name, last name, and status are not empty
                            if (currentChildEntry.childDto.first_name && currentChildEntry.childDto.last_name && currentChildEntry.statusUpdates) {
                                const newChildDto = currentChildEntry.childDto as ChildCreateDtoInterface;
                                delete newChildDto.id;
                                // Create new child with New Family status first and then update later
                                newChildDto.status = 1;
                                const newChild = (await this.childrenRepository.create(currentFamilyId, newChildDto))[0];
                                if (currentChildEntry.statusUpdates) {
                                    currentChildEntry.statusUpdates.child_id = newChild.id;
                                    await this.statusChangeRepository.changeStatus(currentChildEntry.statusUpdates, true);
                                    this.statusChangesStore.clear(currentChildId);
                                }
                            }
                        }
                    }
                }

                // Handle actions like linking and reject families
                const targetFamilyId = parseInt(targetFamilyString);
                if (actionType === PotentialDuplicateActionConstants.MERGE) {
                    await this.familiesRepository.rejectFamily(currentFamilyId, { merge_to_family_id: targetFamilyId });
                }
                if (actionType === PotentialDuplicateActionConstants.LINK) {
                    await this.familiesRepository.linkFamily(currentFamilyId, { family_id: targetFamilyId });
                }
                if (actionType === PotentialDuplicateActionConstants.REJECT) {
                    await this.familiesRepository.rejectFamily(currentFamilyId, {});
                }
            }

        }
    }
}
