




































































































































































































































































































































































































































































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import InlineEditable from '@/components/base/InlineEditable.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { LoadingStore } from '@/store/loading-store';
import PotentialDuplicateService, {
    ChildEntry,
    FamilyEntry,
    MergeLayer,
    PotentialDuplicateActionOption,
    PotentialDuplicateActionsSet
} from '@/families/services/potential-duplicate-service';
import {
    AcceptFamilyEventPayload,
    Family,
    PendingFamily,
    PotentialDuplicateActionConstants
} from '@/families/models/family';
import { LocaleMixin } from '@/locales/locale-mixin';
import DuplicatesDifferenceIndicator
    from '@/families/components/new/potential-duplicates/DuplicatesDifferenceIndicator.vue';
import { CrmTypeOption } from '@/crm-types/models/crm-type';
import DuplicateStatusChangeSelect
    from '@/families/components/new/potential-duplicates/DuplicateStatusChangeSelect.vue';
import {
    buildChildrenOverwritesFromDiffs,
    buildFamilyOverwritesFromDiffs,
    filterNonHideActions,
    getActionOptionsForFamily,
    getDialogSize,
    mergeChildInfo,
    mergeGuardianInfo
} from '@/families/potential-duplicate-utils';
import cloneDeep from 'lodash/cloneDeep';
import { StatusChangeInterface } from '@/families/models/status';
import { getFieldValueByPath, setFieldValueByPath } from '@/utils/object-path-utils';

const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
const potentialDuplicateService = new PotentialDuplicateService();

@Component({
  components: { DuplicateStatusChangeSelect, InlineEditable, StatusChangeSelect, CrmTypeSelectList, DuplicatesDifferenceIndicator }
})

export default class DuplicatesReviewModal extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ type: Array, default: null, required: true }) readonly duplicates!: Array<Family | PendingFamily>;
    @Prop({ type: Boolean, default: false }) readonly isPending!: boolean;

    private loadingKey = 'duplicatesReviewModal';
    private isLoaded = false;
    private localDialogSize = 'potential-duplicate-dialog-medium';
    private originalFamilyEntries: Array<FamilyEntry> = [];
    private currentFamilyEntries: Array<FamilyEntry> = [];
    private originalChildrenGroupedByName: Record<string, Array<ChildEntry>> = {};
    private currentChildrenGroupedByName: Record<string, Array<ChildEntry>> = {};
    private familyDifferencesRecord: Record<string, boolean> = {};
    private childrenDifferencesArray: Array<{ name: string; differences: Record<string, boolean> }> = [];
    private availableInquiryTypes: Array<CrmTypeOption> = [];
    private availableSourceTypes: Array<CrmTypeOption> = [];
    private availableStatusIdsForChildlessFamilies: Array<number> = [];
    private centerNames: Array<string> = [];
    private currentActionOptions: Record<number, PotentialDuplicateActionOption | null> = {};
    private previousActionOptions: Record<number, PotentialDuplicateActionOption | null> = {};
    private actionRecords: Record<number, PotentialDuplicateActionsSet> = {};
    private mergeTracking: Map<number, Array<number>> = new Map();
    private mergeHistory: Map<number, Array<MergeLayer>> = new Map();
    private updatedEvent = EventTypes.UPDATED;

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        this.$emit('input', showIt);
    }

    get familyEntries(): Array<FamilyEntry> {
        return this.currentFamilyEntries;
    }

    set familyEntries(value: Array<FamilyEntry>) {
        this.currentFamilyEntries = value;
        potentialDuplicateService.updateFamilyEntries(value);
    }

    get childrenGroupedByName(): Record<string, Array<ChildEntry>> {
        return this.currentChildrenGroupedByName;
    }

    set childrenGroupedByName(value: Record<string, Array<ChildEntry>>) {
        this.currentChildrenGroupedByName = value;
        potentialDuplicateService.updateChildrenGroupedByName(value);
    }

    get isSaveDisabled() {
        // Filter out records with action type `HIDE`
        const validActions = Object.values(this.currentActionOptions).filter((action) => {
            if (!action) return true; // Skip null values
            const actionType = action.value.split('-')[0];
            return actionType !== PotentialDuplicateActionConstants.HIDE;
        });
        // If any valid action is null, saving should be disabled
        return validActions.some((action) => action === null);
    }

    get isMini() {
        return appState.isMini;
    }

    @Watch('modelValue')
    private async loadFamiliesData() {
        if (this.modelValue) {
            loadingState.loadingIncrement(this.loadingKey);
            this.isLoaded = false;
            await this.initializeData();
            await this.reloadData();

            // Track the actions of save, merge, link, hide, and reject in this component, too much hassle to track in service class
            this.cleanMergeTracking();
            this.cleanMergeHistory();

            this.initializeActions();
            this.isLoaded = true;
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('familyEntries', { deep: true })
    private onFamiliesDtoChange(newValue: Array<FamilyEntry>) {
        potentialDuplicateService.updateFamilyEntries(newValue);
    }

    @Watch('childrenGroupedByName', { deep: true })
    private onChildrenGroupedByNameChange(newValue: Record<string, Array<ChildEntry>>) {
        potentialDuplicateService.updateChildrenGroupedByName(newValue);
    }

    private updateStatusSelect(index: number, status: StatusChangeInterface | null) {
        this.familyEntries[index].statusUpdates = status;
    }

    private updateChildStatusSelect(name: string, index: number, status: StatusChangeInterface | null) {
        this.childrenGroupedByName[name][index].statusUpdates = status;
    }

    private async initializeData() {
        await potentialDuplicateService.init(this.duplicates);
        this.currentFamilyEntries = potentialDuplicateService.familyEntries;
        this.originalFamilyEntries = cloneDeep(this.currentFamilyEntries);

        this.currentChildrenGroupedByName = potentialDuplicateService.childrenGrouped;
        this.originalChildrenGroupedByName = cloneDeep(this.currentChildrenGroupedByName);

        this.centerNames = potentialDuplicateService.centerRows;
        this.familyDifferencesRecord = potentialDuplicateService.familyDifferences;
        this.childrenDifferencesArray = potentialDuplicateService.childrenDifferences;
    }

    private async reloadData() {
        await potentialDuplicateService.setupSelectListOptions(this.familyEntries);
        this.localDialogSize = getDialogSize(this.familyEntries.length);

        this.availableInquiryTypes = potentialDuplicateService.inquiryTypesOptions;
        this.availableSourceTypes = potentialDuplicateService.sourceTypesOptions;
        this.availableStatusIdsForChildlessFamilies = potentialDuplicateService.statusesForChildlessFamilies;
    }

    private initializeActions() {
        this.actionRecords = {};
        this.currentActionOptions = {};
        for (const familyEntry of this.familyEntries) {
            this.actionRecords[familyEntry.familyDto.id] = {
                save: [
                    {
                        text: 'Save This Record',
                        value: `${PotentialDuplicateActionConstants.SAVE}-${familyEntry.familyDto.id}`
                    }
                ],
                merge: [],
                link: [],
                hide: [
                    {
                        text: 'Skip/Hide This Record',
                        value: `${PotentialDuplicateActionConstants.HIDE}-${familyEntry.familyDto.id}`
                    }
                ],
                reject: [
                    {
                        text: 'Reject This Record',
                        value: `${PotentialDuplicateActionConstants.REJECT}-${familyEntry.familyDto.id}`
                    }
                ]
            };
            this.updateSelectedActionOption(familyEntry.familyDto.id, null);
            this.previousActionOptions[familyEntry.familyDto.id] = null;
        }
    }

    private cleanMergeHistory(): void {
      this.mergeHistory = new Map();
    }

    private cleanMergeTracking() {
        this.mergeTracking.clear();
        this.familyEntries.forEach((familyEntry) => {
            this.mergeTracking.set(familyEntry.family.id, []);
        });
    }

    private closeDialog() {
        this.modelValue = false;
        this.$emit(EventTypes.CLOSE);
    }

    private retrieveActionOptions(familyId: number): Array<PotentialDuplicateActionOption> {
        return getActionOptionsForFamily(familyId, this.familyEntries, this.currentActionOptions);
    }

    private async onActionChange(familyId: number, action: PotentialDuplicateActionOption) {
        const actionType = action.value.split('-')[0];
        const previousAction = this.previousActionOptions[familyId];
        const previousActionType = previousAction ? previousAction.value.split('-')[0] : null;

        if (actionType !== PotentialDuplicateActionConstants.SAVE) {
            this.resetReferencesToFamily(familyId);
        }

        if (actionType === PotentialDuplicateActionConstants.MERGE) {
            // We parse out the target from e.g. "merge-4"
            const targetFamilyId = parseInt(action.value.split('-')[1]);
            if (previousAction && previousActionType && previousActionType === PotentialDuplicateActionConstants.MERGE) {
                const previousTargetFamilyId = parseInt(previousAction.value.split('-')[1]);
                if (previousTargetFamilyId !== targetFamilyId) {
                    // UNMERGE if previously "merge" to a different target
                    this.handleUnmergeAction(familyId, previousTargetFamilyId);
                }
            }
            this.handleMergeAction(familyId, targetFamilyId);
        } else {
            // UNMERGE if previously "merge"
            if (previousAction && previousActionType && previousActionType === PotentialDuplicateActionConstants.MERGE) {
                const previousTargetFamilyId = parseInt(previousAction.value.split('-')[1]);
                this.handleUnmergeAction(familyId, previousTargetFamilyId);
            }
        }

        if (actionType === PotentialDuplicateActionConstants.HIDE) {
            await this.handleHideAction(familyId, action);
            return; // Exit early after handling the hide action
        }

        this.updateActionOptions(familyId, action);
        await this.reloadData();
    }

    private handleMergeAction(sourceFamilyId: number, targetFamilyId: number) {
        if (!this.mergeTracking.has(targetFamilyId)) {
            this.mergeTracking.set(targetFamilyId, []);
        }

        const mergesForTarget = this.mergeTracking.get(targetFamilyId) || [];
        // avoid duplicates
        if (!mergesForTarget.includes(sourceFamilyId)) {
            mergesForTarget.push(sourceFamilyId);
            this.mergeTracking.set(targetFamilyId, mergesForTarget);
            // Call `reapplyAllMergesInOrder` so that merges happen in ascending order
            this.reapplyAllMergesInOrder(targetFamilyId, mergesForTarget);
        }
    }

    private handleUnmergeAction(sourceFamilyId: number, previousTargetFamilyId: number) {
        if (!this.mergeTracking.has(previousTargetFamilyId)) return;

        let mergesForTarget = this.mergeTracking.get(previousTargetFamilyId) || [];
        mergesForTarget = mergesForTarget.filter(id => id !== sourceFamilyId);
        this.mergeTracking.set(previousTargetFamilyId, mergesForTarget);

        // partial unmerge to preserve user edits
        this.partialUnmerge(previousTargetFamilyId, sourceFamilyId);

        // re-apply merges from the remaining sources, in ascending ID
        if (mergesForTarget.length > 0) {
            this.reapplyAllMergesInOrder(previousTargetFamilyId, mergesForTarget);
        } else {
            // no merges remain for this target
            this.mergeHistory.delete(previousTargetFamilyId);
            this.mergeTracking.set(previousTargetFamilyId, []);
        }
    }

    private partialUnmerge(targetId: number, sourceId: number): void {
        const layersForTarget = this.mergeHistory.get(targetId);
        if (!layersForTarget) return;

        const layerIndex = layersForTarget.findIndex(l => l.sourceId === sourceId);
        if (layerIndex === -1) return;

        const layer = layersForTarget[layerIndex];
        const targetIndex = this.familyEntries.findIndex(f => f.familyDto.id === targetId);
        if (targetIndex === -1) return;
        const targetFamilyEntry = this.familyEntries[targetIndex];

        // Revert only fields that are still identical to the mergedValue
        for (const overwrite of layer.familyOverwrites) {
            // read the current value
            const currentVal = getFieldValueByPath(targetFamilyEntry, overwrite.fieldPath);
            if (currentVal === overwrite.mergedValue) {
                // revert it
                setFieldValueByPath(targetFamilyEntry, overwrite.fieldPath, overwrite.oldValue);
            }
        }

        for (const [childName, overwrites] of Object.entries(layer.childrenOverwrites)) {
            const targetChildrenEntries = this.childrenGroupedByName[childName];
            if (!targetChildrenEntries) continue; // Ensure the child group exists

            const targetChildEntry = targetChildrenEntries[targetIndex]; // Match family index
            if (!targetChildEntry) continue;

            let updated = false; // Track if modifications happen

            for (const overwrite of overwrites) {
                const currentVal = getFieldValueByPath(targetChildEntry, overwrite.fieldPath);
                if (currentVal === overwrite.mergedValue) {
                    setFieldValueByPath(targetChildEntry, overwrite.fieldPath, overwrite.oldValue);
                    updated = true;
                }
            }

            // **Ensure update is reflected in childrenGroupedByName**
            if (updated) {
                this.childrenGroupedByName[childName][targetIndex] = { ...targetChildEntry };
            }
        }

        // remove the merge layer
        layersForTarget.splice(layerIndex, 1);
    }

    // Returns the column index in `familyEntries` for the given family ID so we know who is left vs. right.
    private getColumnIndexForFamilyId(familyId: number): number {
      return this.familyEntries.findIndex(entry => entry.family.id === familyId);
    }

    private reapplyAllMergesInOrder(targetId: number, sourceIds: number[]) {
        // Sort sources by column index (left to right)
        sourceIds.sort((a, b) => this.getColumnIndexForFamilyId(a) - this.getColumnIndexForFamilyId(b));

        const processedSources = new Set<number>(); // Prevent duplicate processing

        for (const srcId of sourceIds) {
            if (processedSources.has(srcId)) {
                continue; // Skip redundant processing
            }
            this.doMerge(targetId, srcId);
            processedSources.add(srcId);
        }
    }

    // Handle "Skip/Hide This Record" action
    private async handleHideAction(familyId: number, action: PotentialDuplicateActionOption) {
        const result = await this.$swal({
            text: 'Are you sure you want to remove this column from the screen? Any edits you may have made will not be saved.',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Hide',
            cancelButtonText: 'Cancel',
            icon: 'warning'
        });

        if (result.isConfirmed) {
            this.hideFamilyFromDuplicates(familyId);
            await this.reloadData();
            this.updateActionOptions(familyId, action);
        } else {
            await this.reloadData;
            this.revertAction(familyId);
        }
    }

    // Reset references to the specified family when moving away from "Save"
    private resetReferencesToFamily(familyId: number): void {
        this.familyEntries.forEach((familyEntry) => {
            if (this.currentActionOptions[familyEntry.familyDto.id]?.value.includes(`-${familyId}`) && familyEntry.familyDto.id !== familyId) {
                this.updateSelectedActionOption(familyEntry.familyDto.id, null); // Reset to default empty state
                this.handleUnmergeAction(familyEntry.familyDto.id, familyId);

            }
        });
    }

    // Update the selected and current action options
    private updateActionOptions(familyId: number, action: PotentialDuplicateActionOption): void {
        this.updateSelectedActionOption(familyId, action);
        this.previousActionOptions[familyId] = action;
    }

    // Revert the action for a family to the previous state
    private revertAction(familyId: number): void {
        const previousAction = this.previousActionOptions[familyId] || null;
        this.updateSelectedActionOption(familyId, previousAction);
    }

    // Remove a family from the duplicates list and clear references to it
    private hideFamilyFromDuplicates(familyId: number): void {
        const deletedIndex = this.familyEntries.findIndex((family) => family.familyDto.id === familyId);
        if (deletedIndex !== -1) {
            this.familyEntries.splice(deletedIndex, 1);
            this.centerNames.splice(deletedIndex, 1);
            Object.keys(this.childrenGroupedByName).forEach((key) => {
                this.childrenGroupedByName[key].splice(deletedIndex, 1);
            });
        }

        this.familyEntries.forEach((familyEntry) => {
            if (this.currentActionOptions[familyEntry.familyDto.id]?.value.includes(`-${familyId}`)) {
                this.updateSelectedActionOption(familyEntry.familyDto.id, null); // Reset to default empty state
            }
        });
    }

    private updateSelectedActionOption(familyId: number, action: PotentialDuplicateActionOption | null): void {
        this.$set(this.currentActionOptions, familyId, action);
    }

    private doMerge(targetFamilyId: number, sourceFamilyId: number) {
        const targetIndex = this.familyEntries.findIndex(f => f.familyDto.id === targetFamilyId);
        const sourceIndex = this.familyEntries.findIndex(f => f.familyDto.id === sourceFamilyId);
        if (targetIndex === -1 || sourceIndex === -1) return;

            const targetFamilyEntry = this.familyEntries[targetIndex];
            const sourceFamilyEntry = this.familyEntries[sourceIndex];

        // snapshot before
        const beforeFamilyEntry = cloneDeep(targetFamilyEntry);
        const beforeChildrenGroupedByName = cloneDeep(this.childrenGroupedByName);

        // call existing "mergeGuardianInfo" + "mergeChildInfo"
        mergeGuardianInfo(targetFamilyEntry, sourceFamilyEntry, this.familyEntries);
        mergeChildInfo(targetIndex, sourceIndex, this.childrenGroupedByName);

        // snapshot after
        // figure out which fields changed from null -> newValue
        const familyOverwrites = buildFamilyOverwritesFromDiffs(beforeFamilyEntry, targetFamilyEntry);
        const childrenOverwrites = buildChildrenOverwritesFromDiffs(targetIndex, beforeChildrenGroupedByName, this.childrenGroupedByName);

         if (familyOverwrites.length === 0) {
            return; // Skip adding an empty merge record
        }

        // store them in mergeHistory
        if (!this.mergeHistory.has(targetFamilyId)) {
            this.mergeHistory.set(targetFamilyId, []);
        }
        this.mergeHistory.get(targetFamilyId)!.push({
            sourceId: sourceFamilyId,
            familyOverwrites,
            childrenOverwrites
        });

    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        await potentialDuplicateService.save(filterNonHideActions(this.currentActionOptions), this.isPending);
        if (!this.isPending) {
            this.$emit(EventTypes.UPDATED);
        } else {
            const familyBeingViewId = this.familyEntries[0].familyDto.id;
            const familyBeingViewAction = this.currentActionOptions[familyBeingViewId];
            if (familyBeingViewAction) {
                const familyBeingViewActionType = familyBeingViewAction.value.split('-')[0];
                if (familyBeingViewActionType !== PotentialDuplicateActionConstants.REJECT) {
                    this.$emit(EventTypes.FAMILY_ACCEPTED, { acceptedInDuplicateModal: true } as AcceptFamilyEventPayload);
                } else {
                    this.$emit(EventTypes.FAMILY_REJECTED, familyBeingViewId, null, true);
                }
            }
        }
        loadingState.loadingDecrement(this.loadingKey);
        this.closeDialog();
    }

}
